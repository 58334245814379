import axios from "axios";
 
import { apiEndpoint,Authorization } from "../config";

//axios.defaults.withCredentials=true;

const http = axios.create({
  baseURL: ''
})
 
export const setupInterceptors = (navigate) => {
   
  http.interceptors.request.use(function (config) {
    config.headers.set('Content-Type', 'application/json');
     return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
  http.interceptors.response.use(res => {
    // success
    return res
  }, err => {
    console.log('err',err);
    if (err.response && [301, 302].includes(err.response.status)) {
      const redirectUrl = err.response.headers.location;
      return http.get(redirectUrl);
    }
    const { status } = err.response
 
    if (status === 401) {
      // if we get Error code then redirect to Login screen
      navigate('/login')
    }
  
    return Promise.reject(err)
  })
}

export default http