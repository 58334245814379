import { useEffect, useState } from "react";


function CustomTable(props) {
    console.log('props',props);
    const [tableData, setTableData] = useState(props.data);
    const [tableHeader, setTableheader] = useState();
    const [tableHeaderList, setTableheaderList] = useState();
    useEffect(() => {
        console.log('props',props);
        
        let header =[];
        let headerList = Object.keys(props.data[0]);
        headerList.forEach(e=>{
            let h = e.split('(');
            console.log('')
            if(h.length>0){
                header.push(<>
              <div style={{fontSize:18,fontWeight:700}}>{h[0]}</div>
               <div style={{color: 'var(--text-color-grey-darkest, #202124);',fontSize:14,fontWeight:500}}>
                {
                    h[1]!=undefined &&  `(${h[1]}`
                }
                </div>
                </>)
            }else{
                header.push(<div style={{fontSize:18,fontWeight:700}}>{h[0]}</div>)
            }
        })
        setTableheaderList(Object.keys(props.data[0]))
        setTableheader(header)

        setTableData(props.data);
    },[props.data])

    return (
        <table className="w-100 border">
            <thead>
                <tr>
                    {tableHeader && tableHeader.map((e, i) => {
                
                         return (<td key={i} className='border text-center'>{e}</td>) }
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    tableData && tableData.map((d, i) => {
                        return (<tr key={i} className="border"> 
                            {
                                tableHeaderList && tableHeaderList.map((e, j) => {
                                    return (<td key={j}  >{d[e]}</td>);
                                })
                            }
                        </tr>);
                    })
                }
            </tbody>
        </table>
    )

}
export default CustomTable;
