import { useEffect, useState } from "react";
import { object } from "underscore";

export default function ColorCode(props) {
    const [color, setColor] = useState(props.color);
    const [colorData, setColorData] = useState();

    useEffect(() => {
        setColorData(Object.keys(props.color));

    }, [props])
    return (<div>
        <h6>Legend</h6>
        {Object.keys(color).map((key,index) => (
            <div key={index} style={{margin:4,padding:4}}>
                <span style={{backgroundColor:key,paddingLeft:44}}></span><span style={{padding:10}}> {color[key]}</span>
            </div>
        ))}
    </div>
    )
}