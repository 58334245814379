import React, { useState } from "react";
import Header from "./Header";
import { Container } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import UserContext from '../context/UserContext';
function Layout() {
  
  const [supplier, setSupplier] = useState({});
  const getSupplier = (data) => {
    setSupplier(data);
    console.log('Layout Supplier data', data)
  }
  
  return (
    <UserContext.Provider value={{"supplier":supplier}}>
      <Header supplierDetails={getSupplier} />
      <Container fluid className="p-3">
        <Outlet/>
      </Container>
    </UserContext.Provider>
  );
}
export default Layout;