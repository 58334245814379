import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateFilter from './DateFilter';
import { Container } from 'react-bootstrap';
import CustomTable from './CustomTable';
function PerfectTrips(props) {
  const [perfectTripData, setPerfectTripData] = useState();
  const [dateRange, setDateRange] = useState();
   
  const getDateRange = (daterange) => {
    console.log('Main Body Date Range', daterange);
    setDateRange(daterange)
}
  useEffect(() => {
    setPerfectTripData({
      "percentage": "80%",
      "details": [
        {
          "Yesterday": "60%",
          "Day before yesterday": "60%",
          "Last week same day":"60%"
        }
      ]
    })
  }, [])
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
   
      <Modal.Body>
      <Row className='itemAlignleftRight'>
            <Col> 
            <div style={{
            fontSize:20,fontWeight:700
          }}>
          Perfect trip details 
          <span
          style={{
            margin:1,
            fontSize:18, fontWeight:500, padding: "8px 12px",
            borderRadius:8,
            background: "var(--text-color-positive, #1D8844)",
            color:"white", 
          }}
          
          >
            Pefect Trip : {perfectTripData && perfectTripData.percentage}
            </span> 
          </div>
          </Col>
         
            <Col   style={{ textAlign:'end',gap:8}}>
            <DateFilter selecteDateRage={getDateRange} />
            </Col>
          </Row>
         
      {
        perfectTripData && <CustomTable data={perfectTripData.details} />
      }
     
      </Modal.Body>
    </Modal>
  );
}

export default PerfectTrips;