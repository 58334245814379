import React, { useEffect, useState } from "react";
import CardItem from "../components/CardItem";
import { Row, Col } from 'react-bootstrap';
import PerfectTrips from '../components/PerfectTrips';
import Info from '../images/Info.svg'
function DashboardView(props) {
   
    console.log('Dashboard View', props);
    const [serviceLevelData, setServiceLevelData] = useState(props.data);
    const [overviewDescription, setOverviewDescription] = useState('');
    const [overviewClass, setOverviewClass] = useState('');
   
    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        console.log('serviceLevelData', props);
        setServiceLevelData(props.data);
        if (serviceLevelData.perfectTripspercentage != undefined) {
            if (serviceLevelData.perfectTripspercentage > 79) {
                setOverviewClass('overviewSuccess');
                //setOverviewDescription(`Congratulations! You have achieved ${serviceLevelData.perfectTripspercentage}% perfect trips`)
                setOverviewDescription(``)
            } else if (serviceLevelData.perfectTripspercentage <= 79 && serviceLevelData.perfectTripspercentage > 50) {
                setOverviewClass('overviewAvarage');
                setOverviewDescription(`You have achieved ${serviceLevelData.perfectTripspercentage}% perfect trips`)
            } else {
                setOverviewClass('overviewBad');
                setOverviewDescription(`Oops You have achieved only ${serviceLevelData.perfectTripspercentage}% perfect trips`)
            }
        }
    }, [props])
    return (
        <>
        {
            serviceLevelData.details.length ==0 && <>Data Not available</>
        }
            <div style={{ fontSize: 18, fontWeight: 700, marginTop: 10, marginBottom: 10,marginBottom:10 }}>
                {serviceLevelData.type}
            </div>
            <div className={overviewClass}>
                <Row className="itemAlignLeftRight">
                    <Col>
                        <div style={{ fontSize: 18, fontWeight: 500, padding: 4,marginBottom:8 }}>
                            {overviewDescription!='' ? <>{overviewDescription} <img src={Info} alt="infor"/></>:<></> }
                        </div>
                    </Col>
                    <Col style={{ textAlign: "end",gap: 8,color:'#315ED1' , fontSize:14,fontWeight:700 }}>
                      {/* {
                        serviceLevelData.perfectTripspercentage!=undefined ? <span onClick={() => setModalShow(true)}> More Details</span>:<></>
                      }  */}
                    </Col>
                </Row>
                <div className="dashboardFlex">
                    {
                        serviceLevelData.details && serviceLevelData.details.map((e, index) => {
                            console.log('details e serviceLevelData', e, index);
                            return (
                                <div style={{ margin: 4, padding: 2}} key={index} > <CardItem data={{ ...e, selectedDate: props.data.selectedDate }} /></div>
                            )
                        })
                    }
                   
                    {
                         serviceLevelData.details.length == 0 &&    <div style={{ fontSize: 18, fontWeight: 700, marginTop: 10, marginBottom: 10,marginBottom:10 }}>No Data available</div>
                    }
                </div>
            </div>
            <PerfectTrips
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default DashboardView;