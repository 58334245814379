
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { stringify } from 'uuid';

function DateFilter(props) {



    console.log('week start', moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'));
    console.log('week end ', moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'));
    console.log('Month start', moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
    console.log('Month end ', moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));


    const getDayDates = () => {
        moment.updateLocale('en', {
            week: {
                dow: 7, // Sunday is the first day of the week
            },
        });
        let fromdate = moment().add(-1, 'd');
        let todate = moment().add(-1, 'd');

        return {
            id: 1,
            title: 'Daily',
            fromdate: fromdate.format('YYYY-MM-DD') ,
            todate: todate.format('YYYY-MM-DD') ,
            displayDate: `${fromdate.format('DD/MM/YY')} - ${todate.format('DD/MM/YY')}`,
            isActive: false
        }
        
    }
    const getWeeklyDates = () => {

        moment.updateLocale('en', {
            week: {
              dow: 0, // Sunday is the first day of the week
            },
          });
          
          // Example usage
          const startOfWeek =  moment().weekday(0).subtract(1, 'weeks').startOf('week');
          const todate = moment().add(-1, 'd');

        return {
            id: 2,
            title: 'Weekly',
            fromdate: startOfWeek.format('YYYY-MM-DD') ,
            todate:todate.format('YYYY-MM-DD') ,
            displayDate: `${startOfWeek.format("DD/MM/YY (dddd)")} - ${ moment().weekday(0).subtract(1, 'weeks').endOf('week').format("DD/MM/YY (dddd)")}`,
            isActive: false
        }
    }

    const getMontlyDates=()=>{

        moment.updateLocale('en', {
            week: {
                dow: 0, // Sunday is the first day of the week
            },
        });
        let fromdate = moment().subtract(1, 'month').startOf('month') ;
        let todate =moment().add(-1, 'd');

        return  {
            id: 3,
            title: 'Monthly',
            fromdate: fromdate.format('YYYY-MM-DD') ,
            todate: todate.format('YYYY-MM-DD') ,
            displayDate: `${fromdate.format("DD/MM/YY")} - ${moment().subtract(1, 'month').endOf('month').format("DD/MM/YY")}`,
            // fromdate:moment().add(-30,'d').format('YYYY-MM-DD'),   
            // todate: moment().format('YYYY-MM-DD'),
            isActive: false
        }
    }

    const [btnList, setBtnList] = useState([
        getDayDates(),getWeeklyDates(),getMontlyDates()
    ]);
    const [activeBtn, setActiveBtn] = useState({});


    useEffect(() => {
   
        console.log('localStorage', localStorage.getItem('datefilter'));
        if (localStorage.getItem('datefilter')) {
            if (JSON.parse(localStorage.getItem('datefilter')) !== activeBtn) {
                selectButton(JSON.parse(localStorage.getItem('datefilter')))
            }
        } else {
            selectButton(getDayDates())
        }
    }, [])
    const handleDateSelection = (e) => {
        let selectedBtn = JSON.parse(e.currentTarget.getAttribute("data-value"));
        selectButton(selectedBtn);
    }
    function selectButton(selectedBtn) {
        console.log('selected btn', selectedBtn)

        let btnId = btnList.findIndex((obj => obj.id === selectedBtn.id));
        resetallbtnStatus();
        setActiveBtn(selectedBtn);
        btnList[btnId].isActive = true;
        setBtnList(btnList);
        selectedBtn.isActive = true;
        localStorage.setItem('datefilter', JSON.stringify(selectedBtn));
        props.selecteDateRage(selectedBtn);
    }
    function resetallbtnStatus() {
        btnList.forEach(e => {
            e.isActive = false;
        })
        setBtnList(btnList);
    }

    return (
        <>
            <label className="p-3" style={{ fontSize: 16, fontWeight: 400 }}> Sort by </label>
            <span className="dateFilterBtns" style={{ background: "#FFFF" }}>
                {
                    btnList && btnList.map((e, index) => {
                        return (
                            <button
                                key={index}
                                data-value={JSON.stringify(e)}
                                onClick={handleDateSelection}
                                style={{ fontSize: 14, fontWeight: 700, width: 96 }}
                                className={e.isActive ? ' btn bg-black text-white' : 'btn bg-white text-black'}>
                                {e.title}
                            </button>
                        )
                    }
                    )
                }
            </span>

        </>
    )
}
export default DateFilter;
