import React, { useEffect, useState } from 'react';
import { Row, Col, OverlayTrigger } from 'react-bootstrap';
import target from '../images/target.svg';
import ranking from '../images/ranking.svg';
import CircularProgressBar from './CircularProgressbar';
import { useNavigate } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import CustomPopover from './CustomPopover';
 
function CardItem(props) {

    const navigate = useNavigate();
    const [cardData, setCardData] = useState(props.data);
    //  console.log('Outlet context',supplier);
    const handleDetailedView = () => {
        console.log('calling detailed view', props);
        navigate('../details', { state: { ...props } });
    }
    useEffect(() => {
        setCardData(props.data);
    }, [props])
    const getpercentageColor=(data)=>{
        let colors =data.color;
        let percentage = data.percentage;

        let color = Object.keys(colors);
        for(let i=0;i<color.length;i++){
            let values  = colors[color[i]].split('-');
            if(values.length>1 && percentage>=values[0] && percentage<=values[1]){
                return color[i]
            }
        }
        return 'black';
         
    }
    return (
        <div className="cardItem" >
            <div>
                <div style={{ padding: 8, fontSize: 16, fontWeight: 700 }} className='float-start'>
                    {cardData.type}
                    <CustomPopover title={"Formula"} data={cardData} />
                </div>
                <div className='float-end' style={{
                    padding: 8,
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <span style=
                        {{
                            background: "#EDF3FF",
                            padding: 6,
                            borderRadius: 8,
                            fontSize: 13, fontWeight: 700,

                        }}>
                        {
                            cardData.achievement.type === 'Target' ? <img src={target} alt="target" ></img> : (<></>)
                        }
                        {
                            cardData.achievement.type === 'Rank' ? <img src={ranking} alt="rank"></img> : (<></>)
                        }
                        <span style={{ marginLeft: 3 }}>{cardData.achievement.type}: {cardData.achievement.value}</span>
                    </span>
                </div>
            </div>
            <Row>
                <Col md={6} xs={6}>
                    <CircularProgressBar percentage={props.data.percentage} color={getpercentageColor(props.data)}/>
                </Col>
                <Col md={6} xs={6}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flex: "1 0 0"

                    }}>
                        {
                            cardData.metrics.map((e, index) => {
                                return (
                                    <div style={{marginBottom:'12px'}} key={index}>
                                        <div style={{
                                            fontSize: 18, fontWeight: 700,
                                            color: (e.growthpercentage >= 0 ? 'green' :
                                                ((e.growthpercentage < 0 && e.growthpercentage > -5) ? 'orange' : 'red'))
                                        }}
                                        > {
                                                e.growthpercentage < 0 ? '' : '+'}{e.growthpercentage} %</div>
                                        <div style={{ fontSize: 14, fontWeight: 500 }}>{e.text}</div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{height:'75px'}}>
            <Col md={6} xs={6}>
                <span style={{fontSize: 15}}>{props.data.bestPerformingPartnerLabel} :</span>
                </Col>
                    <Col md={6} xs={6}>
                    <div style={{ fontSize: 14, fontWeight: 700 ,color:'green'}}>{props.data.bestPerformingPartner}</div>
                    </Col>
            </Row>
            <div className='text-center' style={{
                fontSize: 16, gap: 24,
                color: 'var(--text-color-link, #2972E5)', cursor: 'pointer'
            }} onClick={handleDetailedView}  >
                View more details
            </div>
            
        </div>
    )
}
export default CardItem;
