import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from './login/Login';
import Layout from './main/Layout';
import Logout from './logout/Logout';
import DetailedView from './main/DetailedView';
import Dashboard from './main/Dashboard';
import InjectAxiosInterceptors from './plugin/InjectAxiosInterceptors';
import  ProtectedRoute from './main/ProtectedRoute';
import Admin from './admin/Admin';
import SSOLogin from './SSOLogin';

function App() {
  return ( 
    <>
      <BrowserRouter basename='/app'>
      <InjectAxiosInterceptors />
      <Fragment>
          <Routes>
          <Route  path="/login" element={<SSOLogin />}></Route>
          <Route  path="/adminlogin" element={<Login />}></Route>
          <Route  path="/admin" element={<Admin />}></Route>
          <Route path="/logout" element={<Logout />}></Route>
          <Route path="partner" element={
            <ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="dashboard" element={<Dashboard/>}/>
            <Route path="details" element={<DetailedView/>} />
          </Route>
          </Routes>
          </Fragment>
      </BrowserRouter>
    </>
  );
}

export default App;
