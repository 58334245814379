import React from 'react' 
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    let location = useLocation();
   let auth_token= getCookie('auth_token');
   let token= getCookie('token');
   if(auth_token=='' | token==''){
    return <Navigate to="/logout" state={{ from: location}} replace />
   }
    
 return children

};

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
export default ProtectedRoute;