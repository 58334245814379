import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

function CircularProgressBar(props) {
  const [percentage, setPercentage] = useState(0);
  const [pathColor,setPathColor]=useState(props.color);
  useEffect(() => {
        // if(props.percentage<=50){
        //     setPathColor('red')
        // }else if(props.percentage>51 && props.percentage<=75){
        //     setPathColor('orange')
        // }else{
        //     setPathColor('green')
        // }
        setPathColor(props.color);
        setPercentage(props.percentage);
    // setTimeout(() => {
    //   if (percentage < props.percentage) {
    //     setPercentage(percentage + 1);
    //   }
    // }, 10);
  }, [percentage,props.percentage]);

  return (
    <div style={{textAlign:"center"}}>
      <div style={{ width: 120,height:120}}>
        <CircularProgressbar value={percentage} text={`${percentage}%`}
        styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
           
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
        
            // Text size
            textSize: '16px',

            textWeight:800,
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 1,
        
            // Can specify path transition in more detail, or remove it entirely
        //  pathTransition: 'none',
            // Colors
            pathColor: `${pathColor}`,
            textColor: `${pathColor}`
          })}
        />
      </div>
    </div>
  );
}
export default CircularProgressBar;