
import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap"; 
import Dropdown from 'react-bootstrap/Dropdown';

import DataServices from "../services/DataServices";

export default function Admin(){
    const [supplierList,setSupplierList]=useState([]);

    const [selectedSupplier, setSelectedSupplier] = useState('');
    useEffect(()=>{
        getSuppliers();
    },[])
    const getSuppliers=async()=>{
  
        let res = await DataServices.getSuppliersList();
        console.log('res',res);
        if(res.code=='ok'){
            setSupplierList(res.response)
        }else{
          console.log('unable to fetch supplier')
        }
      }
      const handleSelect = (e) => {
        let supplier = JSON.parse(e);
        if (supplier.supplier_id !== selectedSupplier.supplier_id) {
          setSelectedSupplier(supplier);
          localStorage.setItem('supplier',JSON.stringify(supplier));
        
        } else {
          console.log('Supplier already selected');
        }
    
      }
    
    return(<> 
    {
        supplierList &&    
        <Dropdown
        className='w-auto' size="lg"
        onSelect={handleSelect} >
        <Dropdown.Toggle variant='white' id="dropdown-basic">
          <span style={{
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700
          }}>{selectedSupplier.name}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{overflow:'scroll', height:400}}>
     
          {
            supplierList && supplierList.map((e, index) => {
              return (
                <Dropdown.Item key={index}
                  style={{ fontSize: 14, padding: 8 }}
                  eventKey={JSON.stringify(e)}>{e.name}</Dropdown.Item>
              )
            })
          }
        </Dropdown.Menu>
      </Dropdown>
   
      }
         <Button >Login</Button>
      </>);
}