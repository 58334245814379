import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logout from '../images/logout.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";
import DataServices from "../services/DataServices";

function Header(props) {
  const navigate = useNavigate();

  const [suppliers, setSupplier] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState('');

  useEffect(() => {

    
    getSuppliers();
   
  }, []);

  const getSuppliers=async()=>{
  
    let res = await DataServices.getSuppliersList();
    console.log('res',res);
    if(res.code=='ok'){
      setSupplier(res.response)
      if(localStorage.getItem('supplier')){
        let selectedsupplier = JSON.parse(localStorage.getItem('supplier'))
        let suppliers = res.response.filter(e=>{
          return e.supplier_id == selectedsupplier.supplier_id

        })
          props.supplierDetails(suppliers);
          setSelectedSupplier(suppliers);
      }else{
        if(res.response.length==1){
          props.supplierDetails(res.response[0]);
          setSelectedSupplier(res.response[0]);
        }else{
          setSelectedSupplier({supplier_id:-1,name:'Select Supplier'});
        }
      }
    }else{
      console.log('unable to fetch supplier')
    }
  }
  const handleSelect = (e) => {
    // console.log(e); 
    let supplier = JSON.parse(e);
    if (supplier.supplier_id !== selectedSupplier.supplier_id) {
      setSelectedSupplier(supplier);
      localStorage.setItem('supplier',JSON.stringify(supplier));
      props.supplierDetails(supplier);
      supplierLogin(supplier);
    } else {
      console.log('Supplier already selected');
    }

  }

  const supplierLogin=async(supplier)=>{
     let res= await DataServices.loginTosupplier(supplier);
     console.log('res',res);
     if(res.code=='ok'){
     }else{
      alert('unable to update Supplier')
     }
  }
  const logoutHandler = async () => {
      localStorage.clear();
      document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "auth_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    
      navigate('/logout')
  }
 
   
  return (
    <Container fluid className="shadow" color="white">
      <Row className="p-1 m-1">
        <Col xs={6}>
          {
            suppliers &&    
            <Dropdown
            className='w-auto' size="lg"
            onSelect={handleSelect} >
            <Dropdown.Toggle variant='white' id="dropdown-basic">
              <span style={{
                fontSize: 24,
                fontStyle: "normal",
                fontWeight: 700
              }}>{selectedSupplier.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{overflow:'scroll', maxHeight:400,minWidth:'300px'}}>
         
              {
                suppliers && suppliers.map((e, index) => {
                  return (
                    <Dropdown.Item key={index}
                      style={{ fontSize: 14, padding: 8 }}
                      eventKey={JSON.stringify(e)}>{e.name}</Dropdown.Item>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
          }
       
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <div style={{ fontSize: 18, fontWeight: 500,pt:1 }}>
            Logout
            <img src={logout} alt="logout" style={{ padding: 2,cursor:'pointer' }} onClick={logoutHandler}></img>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Header;