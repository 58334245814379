const FileSaver = require('file-saver');
const XLSX = require('xlsx');

let utilHelper = {
    downloadReport: function (data, fileName) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = {
            Sheets: { 'data': ws },
            SheetNames: ['data']
        };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const details = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(details, fileName + fileExtension);
    },
    partnerScoreReportMapping: function (type) {
        switch (type) {
            case 'fulfillment':
                return 'Unfulfilled_Bookings';
            case 'eventcoverage':
                return 'Event_Coverage_Errors';
            case 'blocksuccess':
                return 'Block_Errors';
            case 'confirmsuccess':
                return 'Confirm_Errors';
            case 'searchsuccess':
                return 'Search_Errors';
            default:
                return type

        }
    },
}

module.exports = utilHelper;
