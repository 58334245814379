import HttpService from './HttpService';
 
class DataServices {

    constructor() {
    console.log()
        this.http = new HttpService();
        
    }
    async getMetricsDetails(data){
        try {
            const response = await this.http.post(`/api/partners/v2/dispatch/apiPartner/viewMoreDetails`, data);
            return response;
         } catch (error) {
             console.error('error', error);
             return false;
         }
    }
    async getDashboardCards(data){
        try {
            const response = await this.http.post(`/api/partners/v2/dispatch/apiPartner/metrics`, data);
            return response;
         } catch (error) {
             console.error('error', error);
             return false;
         }
    }
    async getReportDetails(data){
        try {
            const response = await this.http.post(`/api/partners/v2/dispatch/apiPartner/report_details`, data);
            return response;
         } catch (error) {
             console.error('error', error);
             return false;
         }
    }
    
   
    async adminlogin(data) {

        try {
            const response = await this.http.post(`/api/partners/adminlogin`,data);
            return response;
        } catch (error) {
            console.error('error', error);
            return false;
        }
    }
    async loginTosupplier(data){
        try {
            const response = await this.http.post(`/api/partners/admin/supplier`,data);
            return response;
        } catch (error) {
            console.error('error', error);
            return false;
        }
    }
    async logoutUsesr(){

        try {
            const response = await this.http.get(`/api/partners/logout`, {});
            return response;
        } catch (error) {
            console.error('error', error);
            return false;
        }
    }
    async logoutUser(data){

        try {
            //console.log('logoutUser-data', data);  
            const response = await this.http.post(`/api/partners/logout`, data);
            return response;
        } catch (error) {
            console.error('error', error);
            return false;
        }
    }
    async getSuppliersList(){
        try {
            const response = await this.http.get(`/api/partners/getsupplier`,null);
            return response;
        } catch (error) {
            console.error('error', error);
            return false;
        }
    }
}
export default new DataServices();
