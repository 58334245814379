import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import DashboardView from "./DashboardView";
import DateFilter from "../components/DateFilter";
import UserContext from '../context/UserContext';
import CustomLoader from '../components/CustomLoader';
import DataServices from '../services/DataServices';
import moment from 'moment';
function Dashboard(props) {

    const contextData = useContext(UserContext);

    console.log('context ,', contextData);
    const [dateRange, setDateRange] = useState();
    const [dashboardData, setDashboardData] = useState( [] )
    const[isLoading,setIsLoading]=useState(false);
    console.log('MainBody', props)
    const getDateRange = (daterange) => {
        console.log('Main Body Date Range', daterange);
        setDateRange(daterange);
    }
    useEffect(() => {
        console.log('Date range Changed', dateRange, props, contextData);
        if (dateRange && contextData != undefined && contextData.supplier.supplier_id != undefined) {
            let request = {
                "supplier_id": contextData.supplier.supplier_id,
                "fromDate": dateRange.fromdate,
                "toDate": dateRange.todate,
                "filter": dateRange.title
            }
            getDashboardCounts(request);
            console.log('Dashboard request', request);
        } else {
            console.log('not found')
        }
    }, [dateRange, contextData])
    async function getDashboardCounts(request) {
        setIsLoading(true)
         let res = await DataServices.getDashboardCards(request);
        if (res.code == 'ok') {
            if(res.response.length>0){
                setDashboardData(res.response);
            }else{
                setDashboardData([]);
            }
           
        } else {
            setDashboardData([]);
        }
        setIsLoading(false)
    }
    return (
        <Container fluid>
            <Row className='itemAlignleftRight'>
                <Col>
                    <div style={{ fontSize: 24, fontWeight: 700 }}>Metrics</div>
                    {dateRange && (<div style={{ fontSize: 16, fontWeight: 900, color: '#5F6772' }}>{dateRange.displayDate}</div>)}
                </Col>
                <Col style={{ textAlign: 'end', gap: 8 }}>
                    <div>
                        <DateFilter selecteDateRage={getDateRange} />
                    </div>
                </Col>
            </Row>
            <div className="mt-4">
                {
                   dashboardData.length > 0  && dashboardData.map((e, index) => {
                        return (
                        e.details.length > 0 ?
                                (<DashboardView key={index}
                            data={{ ...e, selectedDate: dateRange }} />) :<></>
                        )
                    })
                }
                {/* {
                    dashboardData.length == 0 && <div className='h5'>No Data available</div>
                } */}
                {
                    isLoading && <CustomLoader/>
                }
            </div>

        </Container>
    )
}
export default Dashboard;