import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DateFilter from '../components/DateFilter';
import back from '../images/back.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CustomTable from '../components/CustomTable';
import ExporttoExcel from '../components/ExporttoExcel'

import DataServices from '../services/DataServices';
import UserContext from '../context/UserContext';
import CustomLoader from '../components/CustomLoader';
import utilHelper from '../helpers/util';

function DetailedView(props) {
    const location = useLocation();
    const contextData = useContext(UserContext);
    console.log('context ,', contextData);
    console.log('location details', location.state);
    const [dateRange, setDateRange] = useState(location.state.data.selectedDate);
    const [viewType, setViewType] = useState(location.state.data);
    const [metricsData, setMetricsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getDateRange = (daterange) => {
        console.log('Main Body Date Range', daterange);
        setDateRange(daterange)
    }
    const getDetailedView = async () => {
        if (contextData && contextData.supplier.supplier_id != undefined) {
            setMetricsData([]);
            let request =
            {
                "supplier_id": contextData.supplier.supplier_id,
                "metricType": viewType.type.toLowerCase().replace(' ', ''),
                "fromDate": dateRange.fromdate,
                "toDate": dateRange.todate,
                "filter": dateRange.title
            }
            setIsLoading(true)

            let res = await DataServices.getMetricsDetails(request);
            console.log('view details request', request);

            if (res.code == 'ok') {
                setMetricsData(res.response);
            }
            else {
                setMetricsData([]);
            }
            setIsLoading(false)
            // let datafromServer= {
            //     "code":"ok",
            //     "message":"Metrics Data fetched",
            //     "data":[
            //         {
            //             "type":"Metrics",
            //             "details":[
            //                     {
            //                     "Parameter": "Total searches",
            //                     "Yesterday": 1,
            //                     "Day before yesterday": 2,
            //                     "Last week same day": 2
            //                     },
            //                     {
            //                     "Parameter": "Successful searches",
            //                     "Yesterday": 1,
            //                     "Day before yesterday": 23,
            //                     "Last week same day": 44
            //                     },
            //                     {
            //                     "Parameter": "Search deadends",
            //                     "Yesterday": 1,
            //                     "Day before yesterday": 4,
            //                     "Last week same day": 34
            //                     },
            //                     {
            //                     "Parameter": "Search success",
            //                     "Yesterday": 4,
            //                     "Day before yesterday": 4,
            //                     "Last week same day": 33
            //                     }
            //                     ]
            //         },
            //         {
            //             "type":"Top 5 Errors",
            //             "details":[
            //                     {
            //                     "Parameter": "Total searches",
            //                     "Yesterday": 1,
            //                     "Day before yesterday": 2,
            //                     "Last week same day": 2
            //                     },
            //                     {
            //                     "Parameter": "Successful searches",
            //                     "Yesterday": 1,
            //                     "Day before yesterday": 23,
            //                     "Last week same day": 44
            //                     },
            //                     {
            //                     "Parameter": "Search deadends",
            //                     "Yesterday": 1,
            //                     "Day before yesterday": 4,
            //                     "Last week same day": 34
            //                     },
            //                     {
            //                     "Parameter": "Search success",
            //                     "Yesterday": 4,
            //                     "Day before yesterday": 4,
            //                     "Last week same day": 33
            //                     }
            //                     ]
            //         }
            //     ]
            // };
            // if(datafromServer.code=='ok'){
            //     setMetricsData(datafromServer.data);
            // }
        }
    }

    const getReportData = async () => {
        if (contextData && contextData.supplier.supplier_id != undefined) {
            let request =
            {
                "supplier_id": contextData.supplier.supplier_id,
                "metricType": viewType.type.toLowerCase().replace(' ', ''),
                "fromDate": dateRange.fromdate,
                "toDate": dateRange.todate,
                "filter": dateRange.title
            }
            setIsLoading(true)

            let res = await DataServices.getReportDetails(request);
            console.log('view details request', request);
            
            let fileName = utilHelper.partnerScoreReportMapping(viewType.type.toLowerCase().replace(' ', ''));
            if (res.code == 'ok') {
                utilHelper.downloadReport(res.response,fileName);
            }
            setIsLoading(false)  
        }
    }
    useEffect(() => {

        console.log('view Details change event', dateRange, location.state, contextData);
        if (dateRange && contextData) {
            getDetailedView();
        }

        //  

    }, [dateRange, contextData])
    return (<>
        <Container fluid>
            {/* <Row>
                <Col>
                    <div style={{
                        fontSize: 22, fontWeight: 700, display: "flex",
                        alignItems: "center",padding:6,
                        gap: 8
                    }}>
                        <Link to="/partner/dasbhoard"><img src={back} alt="back"></img></Link>
                        <span> Metric: {viewType.type}</span>
                    </div>
                </Col>
                <Col xs={6} style={{ textAlign: "right", gap: 8 }}>
                    <DateFilter selecteDateRage={getDateRange} />
                </Col>
            </Row> */}

            <Row className='itemAlignleftRight'>
                <Col>
                    <div style={{
                        fontSize: 22, fontWeight: 700, display: "flex",
                        alignItems: "center", padding: 6,
                        gap: 8
                    }}>
                        <Link to="/partner/dashboard"><img src={back} alt="back"></img></Link>
                        <span> Metric: {viewType.type}</span>
                    </div>
                </Col>
                <Col style={{ textAlign: 'end' }}>
                    <DateFilter selecteDateRage={getDateRange} />
                </Col>
            </Row>
            <Row>
                {
                    isLoading && <CustomLoader />
                }
                {
                    metricsData.length > 0 ? metricsData.map((e, index) => {
                        return (
                            <div key={index} >
                                {e.details.length > 0 && (
                                    <>
                                        <Row style={{ padding: 10 }}>
                                            <Col style={{ fontSize: 18, fontWeight: 700 }}>
                                                {e.type} {e.message}
                                            </Col>
                                            {/* <Col xs={6} style={{
                                                textAlign: 'right', fontSize: 14, fontWeight: 700,
                                                color: "var(--active-tertiary-default-tertiary-default, #315ED1)"
                                            }}>
                                                <ExporttoExcel excelData={e.details} fileName={`${viewType.type} - ${e.type}`} />
                                            </Col> */}
                                            {index != 0 && <Col>
                                                <div style={{ position: 'relative' }}>
                                                    {metricsData.length > 0 && (
                                                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                                            <button onClick={getReportData}>Download Report</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                            }
                                        </Row>
                                        <CustomTable data={e.details} />
                                    </>)
                                }
                            </div>
                        )
                    }) :
                    <div>No Data available</div>

                }
            </Row>


        </Container>
    </>)
}
export default DetailedView;