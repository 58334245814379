import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ColorCode from './ColorCode';

export default function CustomPopover(props) {
    return (<OverlayTrigger trigger="hover" placement="right" overlay={
        <Popover id="popover-basic">
            <Popover.Header as="h3">{"Information"}</Popover.Header>
            <Popover.Body>

                {
                    props.data?.formula && 
                    <div className='mb-2'>
                        <h6>Formula</h6>
                        <div> {props.data.formula}</div>
                    </div>
                }
                { props.data.color &&  <ColorCode color={props.data.color} />}
            </Popover.Body>
        </Popover>
    }>
        <span style={{marginLeft:2}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
        </span>
    </OverlayTrigger>)
}