
import secureLogin from '../images/secureLogin.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import DataServices from '../services/DataServices';
import { useState } from 'react';
import CustomLoader from '../components/CustomLoader'
import axios from 'axios';
import config from '../config';
function Login() {
    const navigate = useNavigate();
    localStorage.clear();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoginInProgress,setIsLoginInProgress]=useState(false);
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };

    const handleSubmit = async() => {
        //e.preventDefault();
        // Add your login logic here (e.g., API call, authentication, etc.)
        console.log('Email:', email);
        setIsLoginInProgress(true);
   
        let data ={email:email,password:password}
        let response = await DataServices.adminlogin(data)
        console.log('res',response)
        if(response.code=='ok'){
           navigate('/partner/dashboard')
        }else {
            alert(response.response)
        }
        setIsLoginInProgress(false);
      
      };
      const handleKeyPress = (event) => {
        // look for the `Enter` keyCode
        if (event.keyCode === 13 || event.which === 13) {
          handleSubmit()
        }
      }
    
 
    return (
        <div className='loginLayout'>
            <div className='loginContainer'>
                <img src={secureLogin} alt="seucrelogin"></img>   
                <div className="h3" >Sign in</div>
                <div className='h6 pt-0 mt-0' >to view the metrics</div>
                <br/>
                <br/>
                <Form.Control
                    type="text"
                    id="input_username"
                    aria-describedby="usernameHelpBlock"
                    placeholder='Enter Login ID'
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyPress}
                />
                <br/>
                <Form.Control
                    type="password"
                    id="input_password"
                    aria-describedby="passwordHelpBlock"
                    placeholder='Enter password'
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyPress}
                />  
                <br/>
                <br/>
                <Button variant="primary" size="lg" className='w-100' type='submit' 
                onClick={handleSubmit} style={{fontSize:18,fontWeight:700}} >Login</Button>

            </div>
            {isLoginInProgress && <CustomLoader/> }
        </div>
    )
}
export default Login