import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function CustomLoader(){
    return (
        <Spinner  animation='border'
        style={{position:'absolute',top:'50%',left:'50%',
        marginTop: '-12px',
        marginLeft: '-12px' }}
        />
    )
}