import Button from 'react-bootstrap/Button';
import logoutaway from "../images/logoutaway.png"
import DataService from '../services/DataServices'

function Logout() {
//console.log('logout-cookie',document.cookie);
const readCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }

    return null; // Cookie not found
  };
    const role = readCookie('role');
    const logoutHandler = async () => {
        let request = {
            "role": role
        }
        let res = await DataService.logoutUser(request);
        console.log('logoutHandler-res',res);
        window.location.href = res.data[0].redirecturl;
    }
    

    return (
        <div className='loginLayout'>
            <div className='loginContainer'>
                <img src={logoutaway}
                    style={{ width: 160, height: 160 }}
                    alt="logoutaway"></img>
                <br />
                <div style={{ fontSize: 18, fontWeight: 700 }} >You have been successfully logged out</div>
                <div style={{ fontSize: 16, fontWeight: 400 }} >Thank you</div>
                <br />
                <br />
                <br />
                <Button variant="primary" size="lg" className='w-100'
                    onClick={logoutHandler} style={{ fontSize: 18, fontWeight: 700 }}
                >
                    Login
                </Button>

            </div>
        </div>
    )
}

  
export default Logout;